import styled from "styled-components";

export const Heading = styled.h1`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 3rem;
  color: ${(props) => props.theme.colors.heading};
`;
export const MainTitle = styled.h2`
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2.25;
  color: ${(props) => props.theme.colors.heading};
`;

export const SecondaryTitle = styled.h3`
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: ${(props) => props.theme.colors.main};
`;
