import dayjs from "dayjs";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useClickAway } from "react-use";
import styled from "styled-components";
import { ReactComponent as DeleteIcon } from "../../icons/delete_icon.svg";
import { ReactComponent as ThreeDots } from "../../icons/three_dots.svg";
import { ReactComponent as ViewIcon } from "../../icons/view_icon.svg";
import { Caption, FileName } from "./FilesTable";

type FileRowProps = {
  id: string;
  fileName: string;
  clientName: string;
  size: string;
  uploadedDate: Date;
  toggleEmailModal: (bool: boolean) => void;
  onView: () => void;
  onDelete: () => void;
  getFilePath: (filePath: string) => void;
  filePath: string;
};

const Popup = styled.div`
  display: flex;
  flex-direction: row;
  /* position: absolute;
  top: 3rem;
  right: 4rem; */
  /* height: 240px; */
  z-index: 999;
  box-shadow: ${(props) => props.theme.boxShadow.cardShadow};
  background: #fff;
  /* padding: 1rem; */
  button {
    width: 100%;
    display: flex;
    align-items: center;
    background: #fff;
    color: ${(props) => props.theme.colors.main};
    padding: 1rem 1rem;
    text-decoration: none;
    outline: none;
    border: none;
    cursor: pointer;
    &:hover {
      background: #f9f9f9;
    }
    span {
      flex: 1;
      margin-right: 1.5rem;
    }

    svg {
      width: 16px;
      height: 16px;
      fill: ${(props) => props.theme.colors.heading};
    }
    .more-icon {
      cursor: pointer;
    }
    .delete-icon {
      color: #ff4f4f;
      fill: #ff4f4f;
    }
  }
`;

export const FileRow = ({
  fileName,
  clientName,
  size,
  uploadedDate,
  filePath,
  id,
  toggleEmailModal,
  onView,
  getFilePath,
  onDelete,
}: FileRowProps) => {
  const ref = useRef(null);
  useClickAway(ref, () => {
    setIsPopupOpen(false);
  });
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  return (
    <tr style={{ position: "relative" }}>
      <td>
        <FileName href={filePath}>{fileName}</FileName>
      </td>
      <td>
        <Caption>{clientName}</Caption>
      </td>
      <td>
        <Caption>{size}</Caption>
      </td>

      <td>
        <Caption>
          {dayjs(new Date(uploadedDate)).format("DD/MM/YYYY hh:mm")}
        </Caption>
      </td>
      <td style={{ cursor: "pointer" }}>
        <Caption
          onClick={() => {
            toggleEmailModal(true);
            getFilePath(filePath);
          }}
        >
          Send
        </Caption>
      </td>
      <td>
        <Popup ref={ref}>
          <button>
            <Link to={`/file/${id}`} rel="noreferrer" target="_blank">
              Link
            </Link>
          </button>
          <button onClick={onView}>
            <ViewIcon />
          </button>
          <button onClick={onDelete}>
            <DeleteIcon className="delete-icon" />
          </button>
        </Popup>
      </td>
    </tr>
  );
};
