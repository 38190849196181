import { DefaultTheme } from "styled-components";

const theme: DefaultTheme = {
  screens: {
    sm: "640px",
    md: "768px",
    lg: "1024px",
    xl: "1280px",
  },
  fontFamily: {
    main: "Lato, sans-serif",
  },
  colors: {
    main: "#006F83",
    mainHover: "#005f70",
    mainActive: "#b9222a;",
    heading: "#00414D",
    secondary: "#FFF",
    secondaryActive: "#f5f5f5",
    inputBackground: "#EDF1F3",
    tertiary: "#7F94A5",
  },
  fontSize: {
    xs: "0.75rem",
    sm: "0.875rem",
    base: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "4rem",
  },
  fontWeight: {
    hairline: "100",
    thin: "200",
    light: "300",
    normal: "400",
    medium: "500",
    semibold: "600",
    bold: "700",
    extrabold: "800",
    black: "900",
  },
  lineHeight: {
    none: "1",
    tight: "1.25",
    snug: "1.375",
    normal: "1.5",
    relaxed: "1.625",
    loose: "2",
    "3": ".75rem",
    "4": "1rem",
    "5": "1.25rem",
    "6": "1.5rem",
    "7": "1.75rem",
    "8": "2rem",
    "9": "2.25rem",
    "10": "2.5rem",
  },
  boxShadow: {
    buttonShadow: "0px 1px 3px 0px rgb(0, 0, 0, 0.1)",
    cardShadow: "0px 1px 16px 0px rgba(0, 0, 0, 0.05)",
    navShadow: "0px 3px 6px 0px rgba(0, 0, 0, 0.04)",
  },
};

export { theme };
