import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router";
import styled from "styled-components";
import { api } from "../app/api";
import Container from "../components/UI/Container";
import { device } from "../devices";
import { ReactComponent as Logo } from "../icons/Logo.svg";
import { Asset } from "../types/Asset";
const PageContainer = styled.div<{ imageUrl: string }>`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-image: url(${(props) => props.imageUrl});
  object-fit: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  /* Gradient */

  background: linear-gradient(
    90.4deg,
    rgba(255, 255, 255, 0.9) 70.58%,
    rgba(255, 255, 255, 0) 104.09%
  );

  @media ${device.laptop} {
    width: auto;
    padding: 2rem 4rem;

    padding-right: 16rem;
    background: linear-gradient(
      90.4deg,
      rgba(255, 255, 255, 0.9) 40.58%,
      rgba(255, 255, 255, 0) 97.09%
    );
  }
  border-radius: 0px 0px 24px 24px;

  h2 {
    margin: 0 0 0 1rem;
    font-weight: 500;
    font-size: 1rem;
    color: ${(props) => props.theme.colors.main};
  }
  svg {
    height: 32px;
  }

  @media ${device.laptop} {
    h2 {
      font-size: 1.25rem;
    }
  }
`;

const FileDetailsContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 36px;
  padding: 1rem;

  @media ${device.laptop} {
    width: 35%;
    margin-right: auto;
    margin-left: 4rem;
    padding: 3rem 2rem;
  }

  @media ${device.laptopL} {
    padding: 5rem 2rem;
    width: 30%;
  }
`;

const FileDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.86);
  border-radius: 36px;

  @media ${device.laptop} {
    padding: 3rem 2rem;
  }

  @media ${device.laptopL} {
    padding: 5rem 2rem;
  }
`;

const Name = styled.h4`
  font-weight: 500;
  color: ${(props) => props.theme.colors.main};
  margin: 0;
`;

const Size = styled.p`
  font-size: 1rem;
  color: ${(props) => props.theme.colors.tertiary};
  margin: 0;
  margin-top: 1rem;
`;

const DownloadButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem 3rem;
  background: linear-gradient(89.97deg, #155a6a 13.2%, #17adac 76.96%);
  border-radius: 10px;
  color: ${(props) => props.theme.colors.secondary};
  cursor: pointer;

  &:hover {
    opacity: 90%;
  }
`;

const ViewButton = styled(DownloadButton)`
  background: linear-gradient(89.97deg, #ff8843 13.2%, #ffb210 76.96%);
`;
const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5rem;

  ${DownloadButton}:not(:first-child) {
    margin-top: 1rem;
  }
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0;
  /* width: 100%; */
  z-index: 10;
  background: linear-gradient(
    34.61deg,
    rgba(255, 255, 255, 0.9) 43.7%,
    rgba(255, 255, 255, 0.4) 107.02%
  );

  width: 100%;

  padding: 1rem;
  p {
    margin: 0;
    color: ${(props) => props.theme.colors.main};
  }

  border-radius: 24px 24px 0px 0px;

  p:not(:first-child) {
    margin-top: 1rem;
  }

  position: fixed;
  bottom: 0;
  left: 0;
`;
const getFileById = async (id: string) => {
  const res = await api.get(`/asset/${id}`);
  return res.data;
};

export const getSettings = async () => {
  const res = await api.get(`setting`);
  return res.data;
};

const FilePreview = () => {
  const params: { id: string } = useParams();
  const [file, setFile] = useState<any>();
  const [settings, setSettings] = useState<any>();
  useEffect(() => {
    getFileById(params.id).then((res) => setFile(res));
  }, []);

  useEffect(() => {
    getSettings().then((res) => setSettings(res.settings[0]));
  }, []);

  const isLaptop = useMediaQuery({
    query: device.laptop,
  });

  useEffect(() => {
    if (settings !== undefined) {
      document.body.style.backgroundImage = `url(${settings.image})`;
      document.body.style.objectFit = "cover";
      document.body.style.backgroundRepeat = "no-repeat";
      document.body.style.objectFit = "cover";
      document.body.style.backgroundPosition = "center";
    }

    if (!isLaptop) {
      document.body.style.backgroundSize = "auto";
    }

    return () => {
      document.body.style.backgroundImage = ``;
    };
    // min-height: 100vh;
    // width: 100vw;
    // display: flex;
    // flex-direction: column;
    // align-items: flex-start;
    // justify-content: center;
    // background-image: url(${(props) => props.imageUrl});
    // object-fit: cover;
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: 100%;
  }, [settings]);
  const download = (file: Asset) => {
    api
      .get(`/asset/${file?._id}/download`, { responseType: "arraybuffer" })
      .then((buffer) => {
        console.log(buffer);
        const url = window.URL.createObjectURL(new Blob([buffer.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file?.fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  };

  const isZip = file?.fileName.split(".")[1] === "zip";

  const fileLink = isZip
    ? file.filePath
    : `${process.env.REACT_APP_API_URL}asset/${file?._id}/download`;
  return (
    <>
      {settings && settings.image !== undefined && settings?.image ? (
        <Container>
          <Header>
            <Logo />
            <h2>AD Media Cloud</h2>
          </Header>
          <FileDetailsContainer>
            <FileDetails>
              <Name>{file?.fileName && file.fileName}</Name>
              <Size>{file?.totalSize}</Size>

              {console.log()}
              <ActionsContainer>
                <DownloadButton
                  as="a"
                  target="_blank"
                  href={fileLink}
                  style={{ textDecoration: "none", color: "white" }}
                  // onClick={() => download(file)}
                >
                  {file?.filePath && "Download"}
                </DownloadButton>
                <ViewButton
                  as="a"
                  rel="noreferrer"
                  target="_blank"
                  href={file?.filePath}
                  style={{ textDecoration: "none" }}
                >
                  View
                </ViewButton>
              </ActionsContainer>
            </FileDetails>
          </FileDetailsContainer>
        </Container>
      ) : null}
      <Footer>
        <p>All rights reserved</p>
        <p>www.admedia.agency</p>
      </Footer>
    </>
  );
};

export default FilePreview;
