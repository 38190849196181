import { useEffect, useState } from "react";
import "react-dropzone-uploader/dist/styles.css";
import Modal from "react-modal";
import ReactPaginate from "react-paginate";
import { useMutation, useQuery } from "react-query";
import Swal from "sweetalert2";
import { api } from "../../app/api";
import Navigation from "../../components/Navigation/Navigation";
import Button from "../../components/UI/Button";
import Container from "../../components/UI/Container";
import { SecondaryTitle } from "../../components/UI/Heading";
import Input from "../../components/UI/Input";
import {
  customStyles,
  ModalCloseButton,
  ModalContentContainer,
  ModalHeader,
  ModalHeading,
} from "../../components/UI/Modal";
import { ReactComponent as PlusIcon } from "../../icons/plus.svg";
import { ReactComponent as SearchIcon } from "../../icons/search_icon.svg";
import { getSettings } from "../FilePreview";
import { ErrorMessage } from "../Login/Login.styles";
import FilesTable from "./FilesTable";
import {
  FilesContainer,
  FilesHeader,
  PaginationContainer,
  SearchContainer,
} from "./Home.styles";
import Uploader from "./Uploader";

//change api URL back to server URL
const deleteFile = async (id: string) =>
  await (
    await api.delete(`asset/${id}`)
  ).data;

const sendEmail = async (data: { filePath: string; recieverEmail: string }) => {
  const res = await api.post(`send-email`, data);
  return res.data;
};

const updateBackgroundImage = async (file: File) => {
  const data = new FormData();

  data.append("image", file);

  const res = await api.patch(`setting`, data);
  return res.data;
};

function Home() {
  const [searchQuery, setSearchQuery] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [isBackgroundModalOpen, setIsBackgroundModalOpen] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState<any>();
  const [backgroundFile, setBackgroundFile] = useState<any>();
  const [sendEmailError, setSendEmailError] = useState<string>();
  const [sendEmailData, setSendEmailData] = useState<{
    filePath: string;
    recieverEmail: string;
  }>({ filePath: "", recieverEmail: "" });
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const { recieverEmail, filePath } = sendEmailData;

  const {
    data: filesData,
    status: filesStatus,
    refetch: filesRefetch,
  } = useQuery(["files", searchQuery, page, limit], () =>
    api.get(`asset?key=${searchQuery}&page=${page}&limit=${limit}`)
  );

  const deleteFileMutation = useMutation((id: string) => deleteFile(id));
  const sendEmailMutation = useMutation(
    (data: { filePath: string; recieverEmail: string }) => sendEmail(data)
  );

  const updateBackgroundImageMutation = useMutation((file: File) =>
    updateBackgroundImage(file)
  );

  const onDelete = async (id: string) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You cannot undo this move.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteFileMutation.mutate(id, {
          onSuccess: (data) => {
            filesRefetch();
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
          },
        });
      }
    });
  };

  const handlePageClick = (e: any) => {
    console.log(e);

    if (e.selected < page) {
      setPage(page - 1);
    } else {
      setPage(page + 1);
    }
  };

  const onSendEmail = (data: { filePath: string; recieverEmail: string }) => {
    setSendEmailError(undefined);
    sendEmailMutation.mutate(data, {
      onSuccess: (data) => {
        setEmailModalOpen(false);
        setSendEmailData({ filePath: "", recieverEmail: "" });
        // filesRefetch();
        Swal.fire(
          "Success!",
          "Your email has been sent successfully.",
          "success"
        );
      },
      onError: () => setSendEmailError("Failed to send email."),
    });
  };

  useEffect(() => {
    getSettings().then((res) => setBackgroundImage(res.settings[0]?.image));
  }, []);

  const changeBackgroundImage = () => {
    backgroundFile &&
      updateBackgroundImageMutation.mutate(backgroundFile, {
        onSuccess: (data) => {
          setIsBackgroundModalOpen(false);

          setBackgroundImage(data.setting?.image);

          Swal.fire(
            "Success!",
            "Background image was successfully updated",
            "success"
          );
        },
      });
  };

  return (
    <Container>
      <Navigation />
      <SearchContainer>
        <Input
          placeholder="Search for files..."
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
        />
        <SearchIcon width={24} height={24} />
      </SearchContainer>
      <FilesContainer>
        <FilesHeader>
          <SecondaryTitle>Your files</SecondaryTitle>
          <Button type="button" onClick={() => setIsBackgroundModalOpen(true)}>
            <span>Change Background</span>
          </Button>
          <Button type="button" onClick={() => setIsOpen(true)}>
            <PlusIcon width={24} height={24} />
            <span>Upload a file</span>
          </Button>
        </FilesHeader>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => setIsOpen(false)}
          style={customStyles(modalIsOpen)}
        >
          <ModalContentContainer>
            <ModalHeader>
              <ModalHeading>Upload file</ModalHeading>
              <ModalCloseButton onClick={() => setIsOpen(false)}>
                {/* <CloseIcon /> */}X
              </ModalCloseButton>
            </ModalHeader>
            <div style={{ padding: "1rem" }}>
              <Uploader
                onFinish={() => {
                  setIsOpen(false);
                  filesRefetch();
                  Swal.fire({
                    title: "Success!",
                    text: "Your file has been successfully uploaded.",
                    icon: "success",
                  });
                }}
              />
            </div>
          </ModalContentContainer>
        </Modal>
        <Modal
          isOpen={emailModalOpen}
          onRequestClose={() => setEmailModalOpen(false)}
          style={customStyles(modalIsOpen)}
        >
          <ModalContentContainer>
            <ModalHeader>
              <ModalHeading>Send in a email</ModalHeading>
              <ModalCloseButton
                onClick={() => {
                  setEmailModalOpen(false);
                  setSendEmailData({ ...sendEmailData, recieverEmail: "" });
                }}
              >
                {/* <CloseIcon /> */}X
              </ModalCloseButton>
            </ModalHeader>

            <div
              style={{
                padding: "1rem",
                display: "flex",
                justifyContent: "center",
                gap: "2%",
              }}
            >
              <Input
                placeholder="Email"
                id="email"
                type="text"
                value={recieverEmail}
                onChange={(e) =>
                  setSendEmailData({
                    ...sendEmailData,
                    recieverEmail: e?.target.value,
                  })
                }
              />
              <Button onClick={() => onSendEmail(sendEmailData)}>Send</Button>
            </div>
            {sendEmailError && (
              <ErrorMessage style={{ textAlign: "center" }}>
                {sendEmailError}
              </ErrorMessage>
            )}
          </ModalContentContainer>
        </Modal>

        <Modal
          isOpen={isBackgroundModalOpen}
          onRequestClose={() => setIsBackgroundModalOpen(false)}
          style={customStyles(modalIsOpen)}
        >
          <ModalContentContainer>
            <ModalHeader>
              <ModalHeading>Change file preview background image</ModalHeading>

              <ModalCloseButton
                onClick={() => {
                  setIsBackgroundModalOpen(false);
                  setBackgroundImage("");
                }}
              >
                {/* <CloseIcon /> */}X
              </ModalCloseButton>
            </ModalHeader>
            <div style={{ textAlign: "center", marginTop: "1rem" }}>
              File :
              <span
                style={{ cursor: "pointer", marginLeft: "1rem" }}
                onClick={() => window.open(backgroundImage, "_blank")}
              >
                View
              </span>
            </div>

            <div
              style={{
                padding: "1rem",
                display: "flex",
                justifyContent: "center",
                gap: "2%",
              }}
            >
              <Input
                placeholder="Email"
                id="email"
                type="file"
                onChange={(e) =>
                  setBackgroundFile(e.target.files && e.target.files[0])
                }
              />
              <Button onClick={changeBackgroundImage}>Change</Button>
            </div>

            {updateBackgroundImageMutation.isLoading && (
              <div style={{ textAlign: "center" }}> Loading... </div>
            )}
          </ModalContentContainer>
        </Modal>
        {filesStatus === "loading" && "Loading..."}
        {filesStatus === "error" && "Error..."}
        {filesData?.data && filesStatus === "success" && (
          <FilesTable
            files={filesData?.data.results}
            onDelete={onDelete}
            toggleEmailModal={(bool: boolean) => setEmailModalOpen(bool)}
            getFilePath={(filePath: string) =>
              setSendEmailData({ ...sendEmailData, filePath })
            }
          />
        )}
      </FilesContainer>

      <PaginationContainer>
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={filesData?.data.count / limit}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      </PaginationContainer>
    </Container>
  );
}

export default Home;
