import { motion } from "framer-motion";
import styled from "styled-components";
import { device } from "../../devices";

export const customStyles = (isOpen: boolean) => {
  return {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      zIndex: 9999,
    },
    content: {
      // position: "absolute",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: 0,
      // animation: `${isOpen ? "scaleUp" : "scaleDown"} 300ms`,
      // opacity: isOpen ? "1" : "0",
    },
  };
};

export const ModalContentContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 340px;
  padding: 1rem 2rem;
  @media ${device.laptop} {
    width: 800px;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ececec;
  padding: 1rem 2rem;
`;
export const ModalHeading = styled.h4`
  margin: 0px;
  flex: 1;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 600;
  color: ${(props) => props.theme.colors.heading};
`;

export const ModalCloseButton = styled.button`
  background: #fff;
  outline: none;
  border: none;
  cursor: pointer;
  svg {
    margin-top: 4px;
    width: 16px;
    height: 16px;
  }
`;
