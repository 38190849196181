import styled from "styled-components";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";

export const FilesHeader = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  span {
    margin-left: 0.5rem;
  }
  ${Button} {
    margin-right: 0.5rem;
  }
`;
export const FilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  margin-top: 2rem;
  /* Input Color */

  /* border: 1px solid #edf1f3; */
  box-shadow: ${(props) => props.theme.boxShadow.cardShadow};
  border-radius: 16px;
  padding: 2rem;
  padding-top: 1rem;
`;

export const SearchContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;

  ${Input} {
    width: 100%;
  }
  svg {
    position: absolute;
    right: 1.5rem;
    top: 1rem;
  }
`;
export const PaginationContainer = styled.div`
  .pagination ul {
    display: inline-block;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .pagination li {
    display: inline-block;
    background: ${(props) => props.theme.colors.inputBackground};
    padding: 1rem;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .active {
    color: ${(props) => props.theme.colors.main};
    font-weight: 600;
  }
`;
