import styled from "styled-components";

const Input = styled.input`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  background: ${(props) => props.theme.colors.inputBackground};
  border: none;
  border-radius: 0.5rem;
  outline: ${(props) => props.theme.colors.main};
  color: ${(props) => props.theme.colors.heading};
  font-size: ${(props) => props.theme.fontSize.base};
`;

export default Input;
