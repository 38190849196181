import styled from "styled-components";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import { device } from "../../devices";

export const LoginForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 100%;

  & ${Input} {
    width: 100%;
  }

  @media ${device.laptop} {
    width: 50%;
  }
`;

export const ErrorMessage = styled.p`
  width: 100%;
  color: red;
  align-self: flex-start;
  text-align: start;
  margin-bottom: 1rem;
`;

export const FormControl = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  margin-top: 1.5rem;
  justify-content: flex-end;
  flex-direction: column;
  label {
    margin-bottom: 0.5rem;
  }

  & > div {
    width: 100%;
  }
  ${ErrorMessage} {
    margin-bottom: 1rem;
  }
  input,
  textarea {
    width: 100%;
    flex: 4;
    padding: 1rem 1.25rem;
  }
  textarea {
    resize: vertical;
  }
  & a {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1rem;
  }

  & ${Button} {
    margin-top: 1rem;
  }
`;
