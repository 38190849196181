import styled from "styled-components";

const Button = styled.button`
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  background: ${(props) => props.theme.colors.main};
  border: none;
  border-radius: 0.5rem;
  outline: ${(props) => props.theme.colors.main};
  color: #fff;
  cursor: pointer;
  font-size: ${(props) => props.theme.fontSize.base};

  &:hover {
    background: ${(props) => props.theme.colors.mainHover};
  }
  text-align: center;

  &:disabled {
    opacity: 0.5;
    background: ${(props) => props.theme.colors.main};
    cursor: not-allowed;
  }
`;

export default Button;
