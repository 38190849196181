import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../icons/Logo.svg";
import { NavigationContainer } from "./Navigation.styles";

const Navigation = () => {
  return (
    <NavigationContainer>
      <Logo />

      <Link to="/logout">Logout</Link>
    </NavigationContainer>
  );
};

export default Navigation;
