import styled from "styled-components";
import { device, sizes } from "../../devices";

const Container = styled.div`
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  margin-top: 10rem;
  width: 100%;
  padding: 0 1rem;
  @media ${device.laptopL} {
    max-width: ${sizes.laptopL};
    padding: 0 1rem;
    margin-top: 8rem;
  }
  flex: 1;
`;

export default Container;
