import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import Button from "../../components/UI/Button";
import Container from "../../components/UI/Container";
import Input from "../../components/UI/Input";
import { ReactComponent as Logo } from "../../icons/Logo.svg";
import { getAuth, login, selectAuthStatus } from "../../slices/auth";
import { ErrorMessage, FormControl, LoginForm } from "./Login.styles";

function Login() {
  const auth = useSelector(getAuth);
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const status = useSelector(selectAuthStatus);

  console.log(status);
  if (auth.isLoggedIn) {
    return <Redirect to="/" />;
  }

  const handleSubmit = () => {
    dispatch(login({ email, password }));
  };

  const isEmailEmpty = email.trim().length === 0;
  const isPasswordEmpty = password.trim().length === 0;

  const isSubmitDisabled = isEmailEmpty || isPasswordEmpty;
  return (
    <Container style={{ justifyContent: "center", alignItems: "center" }}>
      <LoginForm>
        <Logo
          style={{ alignSelf: "center" }}
          height={"128px"}
          width={"143px"}
        />
        <FormControl>
          <div>
            <ErrorMessage>
              {isEmailEmpty && isSubmitted && "Email field must not be empty."}
            </ErrorMessage>

            <Input
              placeholder="Email"
              id="email"
              type="text"
              value={email}
              onChange={(e) => setEmail(e?.target.value)}
            />
          </div>
        </FormControl>
        <FormControl>
          <div>
            <ErrorMessage>
              {isPasswordEmpty &&
                isSubmitted &&
                "Password field must not be empty."}
            </ErrorMessage>

            <Input
              placeholder="Password"
              id="email"
              type="password"
              value={password}
              onChange={(e) => setPassword(e?.target.value)}
            />
          </div>
        </FormControl>
        <ErrorMessage>
          {status === "error" && "Invalid username or password."}
        </ErrorMessage>
        <Button
          type="button"
          style={{ marginTop: "2rem" }}
          onClick={handleSubmit}
          disabled={isSubmitDisabled}
        >
          {status === "pending" ? "..." : "Login"}
        </Button>
      </LoginForm>
    </Container>
  );
}

export default Login;
