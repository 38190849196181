import React from "react";
import styled from "styled-components";
import { Asset } from "../../types/Asset";
import { FileRow } from "./FileRow";

export const FileName = styled.a`
  font-weight: 600;
  font-size: 1rem;
  color: ${(props) => props.theme.colors.main};
`;

export const Caption = styled.span`
  font-size: 1rem;
  color: ${(props) => props.theme.colors.tertiary};
`;
const Table = styled.table`
  & {
    border-collapse: collapse;
    width: 100%;
    border-radius: 1rem;
  }

  & td,
  & th {
    /* border: 1px solid #ddd; */
    text-align: left;

    padding: 1.5rem;
  }

  & tr:hover {
    background-color: #f8f8f8;
  }

  & th {
    padding: 1.5rem;

    text-align: left;
    background-color: ${(props) => props.theme.colors.inputBackground};
    color: ${(props) => props.theme.colors.main};
  }

  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.main};
  }
`;

type Props = {
  files: Asset[];
  onDelete: (id: string) => void;
  toggleEmailModal: (bool: boolean) => void;
  getFilePath: (filePath: string) => void;
};

function FilesTable({ files, onDelete, toggleEmailModal, getFilePath }: Props) {
  return (
    <Table>
      <tr>
        <th>File name</th>
        <th>Client name</th>
        <th>Size</th>
        <th>Uploaded Date</th>
        <th>Send Email</th>
        <th>&nbsp;</th>
      </tr>

      {files.length > 0 &&
        files.map((file) => {
          return (
            <FileRow
              id={file._id}
              clientName={file.client}
              fileName={file.fileName}
              onDelete={() => onDelete(file._id)}
              onView={() => {
                window.open(file.filePath, "_blank");
              }}
              size={file.totalSize}
              uploadedDate={file.updatedAt}
              toggleEmailModal={toggleEmailModal}
              getFilePath={() => getFilePath(file._id)}
              filePath={file.filePath}
            />
          );
        })}
    </Table>
  );
}

export default FilesTable;
