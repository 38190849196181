import styled from "styled-components";
import { sizes } from "../../devices";

export const NavigationContainer = styled.nav`
  display: flex;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 80;
  margin-top: 0;
  background: #fff;
  height: auto;
  justify-content: space-between;
  align-items: center;

  margin: 1rem auto;
  max-width: ${sizes.laptopL};
  padding: 0 1rem;
  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.heading};
  }
`;
