import React, { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { useDispatch } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import CheckToken from "../components/CheckToken";
import PrivateRoute from "../components/PrivateRoute";
import FilePreview from "../pages/FilePreview";
import Home from "../pages/Home/Home";
import Login from "../pages/Login/Login";
import { logout, setUser } from "../slices/auth";
import { theme } from "../theme";
import "./App.css";
const queryClient = new QueryClient();

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(
        setUser({
          token: localStorage.getItem("token"),
          user: JSON.parse(localStorage.getItem("user")!),
        })
      );
    }
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <div className="App">
          <BrowserRouter>
            <CheckToken />
            <Switch>
              <PrivateRoute component={Home} path="/" exact />
              <Route component={FilePreview} path="/file/:id" exact />

              <Route path="/login" exact>
                <Login />
              </Route>

              <PrivateRoute
                path="/logout"
                exact
                component={() => {
                  dispatch(logout(0));
                  return <Redirect to="/"></Redirect>;
                }}
              />
            </Switch>
          </BrowserRouter>
        </div>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
