import React, { useEffect, useState } from "react";
import Dropzone, {
  formatBytes,
  formatDuration,
  ILayoutProps,
  IPreviewProps,
} from "react-dropzone-uploader";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { api } from "../../app/api";
import Input from "../../components/UI/Input";
//@ts-ignore
import cancelImg from "../../icons/cancel.svg";
//@ts-ignore
import removeImg from "../../icons/remove.svg";
//@ts-ignore
import restartImg from "../../icons/restart.svg";
import { getAuth } from "../../slices/auth";
import { ErrorMessage } from "../Login/Login.styles";

const iconByFn = {
  cancel: { backgroundImage: `url(${cancelImg})` },
  remove: { backgroundImage: `url(${removeImg})` },
  restart: { backgroundImage: `url(${restartImg})` },
};

type Props = {
  percentage: string;
  status: string;
  errorMessage: string;
};
const ProgressBar = ({ percentage, status, errorMessage }: Props) => {
  console.log(status);
  return (
    <div
      className="progressContainer"
      style={{ visibility: parseInt(percentage) > 0 ? "visible" : "hidden" }}
    >
      {/* <progress
              max={100}
              value={status === "done" ? 100 : percent > 90 ? 90 : percent}
            /> */}
      <progress
        max={100}
        value={status === "uploadingToAws" ? 70 : percentage}
      />
      {status === "uploadingToAws" && (
        <span className="status-upload">Uploading to AWS</span>
      )}

      {status === "error" && (
        <ErrorMessage as={"span"} style={{ fontSize: "14px" }}>
          {errorMessage}
        </ErrorMessage>
      )}
    </div>
  );
};
const UploaderContainer = styled.div`
  ${Input} {
    width: 100%;
    margin: 2rem 0;
  }

  .progressContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1rem;
    padding-bottom: 2.5rem;
    .status-upload {
      font-size: 14px;
      color: ${(props) => props.theme.colors.main};
      margin-top: 0.5rem;
    }

    span {
      margin-bottom: 0;
    }

    progress {
      align-self: flex-end;
      width: 100%;
    }
  }
`;
const Layout = ({
  input,
  previews,
  submitButton,
  dropzoneProps,
  files,
  extra: { maxFiles },
}: ILayoutProps) => {
  return (
    <div {...dropzoneProps}>
      <div style={{ width: "100%" }}>{previews}</div>
      {files.length < maxFiles && input}
      <div
        style={{
          visibility: files.length > 0 ? "visible" : "hidden",
          position: "fixed",
          bottom: "0",
        }}
      >
        {submitButton}
      </div>
    </div>
  );
};

interface IPreviewPropsExtended extends IPreviewProps {
  onRemove: () => void;
}
const Preview = (props: IPreviewPropsExtended) => {
  const {
    className,
    imageClassName,
    style,
    imageStyle,
    fileWithMeta: { cancel, remove, restart },
    meta: {
      name = "",
      percent = 0,
      size = 0,
      previewUrl,
      status,
      duration,
      validationError,
    },
    isUpload,
    canCancel,
    canRemove,
    canRestart,
    onRemove,
    extra: { minSizeBytes },
  } = props;

  let title = `${name || "?"}, ${formatBytes(size)}`;
  if (duration) title = `${title}, ${formatDuration(duration)}`;

  if (status === "error_file_size" || status === "error_validation") {
    return (
      <div className={className} style={style}>
        <span className="dzu-previewFileNameError">{title}</span>
        {status === "error_file_size" && (
          <span>{size < minSizeBytes ? "File too small" : "File too big"}</span>
        )}
        {status === "error_validation" && (
          <span>{String(validationError)}</span>
        )}
        {canRemove && (
          <span
            className="dzu-previewButton"
            style={iconByFn.remove}
            onClick={() => {
              onRemove();
              remove();
            }}
          />
        )}
      </div>
    );
  }

  if (
    status === "error_upload_params" ||
    status === "exception_upload" ||
    status === "error_upload"
  ) {
    title = `${title} (upload failed)`;
  }
  if (status === "aborted") title = `${title} (cancelled)`;

  return (
    <div className={className} style={style}>
      {<span className="dzu-previewFileName">{title}</span>}

      <div className="dzu-previewStatusContainer">
        {isUpload && (
          <div
            className="progressContainer"
            style={{ visibility: percent > 0 ? "visible" : "hidden" }}
          >
            <progress
              max={100}
              value={status === "done" ? 100 : percent > 90 ? 90 : percent}
            />

            {percent === 100 &&
              status !== "done" &&
              status !== "error_upload" && (
                <span className="status-upload">Uploading to AWS</span>
              )}

            {status === "error_upload" && (
              <ErrorMessage as={"span"} style={{ fontSize: "14px" }}>
                There was an error in uploading your file.
              </ErrorMessage>
            )}
          </div>
        )}

        {status === "uploading" && canCancel && (
          <span
            className="dzu-previewButton"
            style={iconByFn.cancel}
            onClick={cancel}
          />
        )}
        {status !== "preparing" &&
          status !== "getting_upload_params" &&
          status !== "uploading" &&
          canRemove && (
            <span
              className="dzu-previewButton"
              style={iconByFn.remove}
              onClick={() => {
                remove();
                onRemove();
              }}
            />
          )}
        {[
          "error_upload_params",
          "exception_upload",
          "error_upload",
          "aborted",
          "ready",
        ].includes(status) &&
          canRestart && (
            <span
              className="dzu-previewButton"
              style={iconByFn.restart}
              onClick={restart}
            />
          )}
      </div>
    </div>
  );
};

type UploaderProps = {
  onFinish: () => void;
};
function Uploader(props: UploaderProps) {
  const [client, setClient] = useState("");
  const [percentUploaded, setPercentUploaded] = useState(0);
  const [status, setStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const auth = useSelector(getAuth);

  const isDisabled = client.trim().length === 0;
  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }: any, status: any) => {};

  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = (files: any, allFiles: any) => {
    let percent = 0;
    const formData = new FormData();

    formData.append("client", client);
    files.forEach((file: any) => formData.append("assets", file.file));

    setStatus("pending");
    api
      .post(`${process.env.REACT_APP_API_URL}asset`, formData, {
        onUploadProgress: (progressEvent: ProgressEvent) => {
          const { loaded, total } = progressEvent;
          percent = Math.floor((loaded * 100) / total) / 1.4;

          setPercentUploaded(percent);
          console.log(percent);
          if (percent >= 68) {
            setStatus("uploadingToAws");
          }
        },
      })
      .then((res) => {
        props.onFinish();
        return (
          setPercentUploaded(percent),
          () => {
            setTimeout(() => {
              setPercentUploaded(0);
            }, 1000);
          }
        );
      })
      .catch((error) => {
        if (error.response) {
          setStatus("error");
          setErrorMessage(error.response.data.error);
        }
      });
  };

  useEffect(() => {
    console.log(percentUploaded);
  }, [percentUploaded]);
  return (
    <UploaderContainer>
      <Input
        placeholder="Client name"
        value={client}
        onChange={(e) => setClient(e.target.value)}
      />
      <Dropzone
        // getUploadParams={() => console.log(2)}
        onChangeStatus={handleChangeStatus}
        onSubmit={handleSubmit}
        // SubmitButtonComponent={undefined}
        // autoUpload={true}
        PreviewComponent={(props) => (
          <Preview {...props} onRemove={() => setPercentUploaded(0)} />
        )}
        LayoutComponent={Layout}
        disabled={isDisabled}
      />
      <ProgressBar
        percentage={percentUploaded.toString()}
        status={status}
        errorMessage={errorMessage}
      />
    </UploaderContainer>
  );
}

export default Uploader;
